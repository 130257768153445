export default [
  {
    textId: 'cashRegister',
    items: [
      {
        textId: 'createUser',
        route: '/user-create',
      },
      {
        textId: 'balances',
        route: '/users-list',
      },
      {
        textId: 'cashiersTransactions',
        route: '/total',
      },
      {
        textId: 'transactionHistory',
        route: '/transaction-history',
      },
      {
        textId: 'revenuBalances',
        route: '/revenu-balances',
      }
    ]
  },
  // {
  //   textId: 'partners',
  //   items: [
  //     {
  //       textId: 'transactionHistory',
  //       route: '/transaction-history',
  //     }
  //   ]
  // },
]
